<template>
  <div class="content">
    <div class="title title--big title--theme title--indent">Добавление контракта</div>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group label-for="kosgu" label="КОСГУ" required>
            <app-input
              @change.native="$v.form.kosgu.$touch()"
              v-model.number="form.kosgu"
              id="kosgu"
              type="number"
              min="0"
              :error="$v.form.kosgu.$error"
            />
            <template #error>
              <div v-if="$v.form.kosgu.$dirty && !$v.form.kosgu.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="name" label="Наименование объекта закупки" required>
            <app-input
                @change.native="$v.form.name.$touch()"
                v-model.trim="form.name"
                id="name"
                :error="$v.form.name.$error"
            />
            <template #error>
              <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="main_price" label="Начальная (максимальная) цена контракта" required>
            <app-input
                @change.native="$v.form.main_price.$touch()"
                v-model.number="form.main_price"
                id="main_price"
                type="number"
                min="0"
                step="0.01"
                :error="$v.form.main_price.$error"
            />
            <template #error>
              <div v-if="$v.form.main_price.$dirty && !$v.form.main_price.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Город" required>
            <app-select
              v-model="form.city"
              :reduce="item => item.id"
              :options="city_options"
              :filterable="false"
              :clearable="true"
              :searchable="true"
              label="id"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.city.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </app-select>
            <template #error>
              <div v-if="$v.form.city.$dirty && !$v.form.city.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Подразделение" required>
            <app-select
              v-model="form.department"
              :reduce="item => item.id"
              :options="department_options"
              :filterable="true"
              :clearable="true"
              :searchable="true"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.department.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </app-select>
            <template #error>
              <div v-if="$v.form.department.$dirty && !$v.form.department.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="executor" label="Исполнитель" required>
            <app-select
              v-model="form.executor"
              :reduce="item => item.id"
              :options="executor_options"
              :filterable="true"
              :clearable="true"
              :searchable="true"
              label="full_name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.executor.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.full_name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.full_name }}</div>
              </template>
            </app-select>
            <template #error>
              <div v-if="$v.form.executor.$dirty && !$v.form.executor.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="date_end" label="Планируемый срок заключения договора / оказания услуги / поставки товара" required>
            <app-input
              @change.native="$v.form.date_end.$touch()"
              v-model.trim="form.date_end"
              id="date_end"
              :error="$v.form.date_end.$error"
            />
            <template #error>
              <div v-if="$v.form.date_end.$dirty && !$v.form.date_end.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Тип размещения (аукцион, электронный магазин, прямой договор)" required>
            <app-select
              v-model="form.type_allocation"
              :reduce="item => item.id"
              :options="allocation_options"
              :filterable="true"
              :clearable="true"
              :searchable="true"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.type_allocation.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </app-select>
            <template #error>
              <div v-if="$v.form.type_allocation.$dirty && !$v.form.type_allocation.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Этап осуществления закупки" required>
            <app-select
              v-model="form.stage"
              :reduce="item => item.id"
              :options="stage_options"
              :filterable="true"
              :clearable="true"
              :searchable="true"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.stage.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </app-select>
            <template #error>
              <div v-if="$v.form.stage.$dirty && !$v.form.stage.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="date_documents_ur" label="Срок передачи документов юристу">
            <app-input
              @paste.native.prevent
              @input.native="$v.form.date_documents_ur.$touch"
              v-model="form.date_documents_ur"
              v-mask="'99.99.9999'"
              id="date_documents_ur"
              autocomplete="off"
              :error="$v.form.date_documents_ur.$error"
            />
            <template #error>
              <div v-if="$v.form.date_documents_ur.$dirty && !$v.form.date_documents_ur.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
          <app-form-group label-for="date_on_buy" label="Срок размещения закупки">
            <app-input
              @paste.native.prevent
              @input.native="$v.form.date_on_buy.$touch"
              v-model="form.date_on_buy"
              v-mask="'99.99.9999'"
              id="date_on_buy"
              autocomplete="off"
              :error="$v.form.date_on_buy.$error"
            />
            <template #error>
              <div v-if="$v.form.date_on_buy.$dirty && !$v.form.date_on_buy.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
          <app-form-group label-for="date_end_req" label="Дата и время окончания приема заявок">
            <app-input
              @paste.native.prevent
              @input.native="$v.form.date_end_req.$touch"
              v-model="form.date_end_req"
              v-mask="'99.99.9999 99:99'"
              id="date_end_req"
              autocomplete="off"
              :error="$v.form.date_end_req.$error"
            />
            <template #error>
              <div v-if="$v.form.date_end_req.$dirty && !$v.form.date_end_req.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
          <app-form-group label-for="req_count" label="Количество заявок">
            <app-input
              v-model.number="form.req_count"
              id="req_count"
              type="number"
              min="0"
            />
          </app-form-group>
          <app-form-group label-for="summary" label="Цена договора" required>
            <app-input
              @change.native="$v.form.summary.$touch()"
              v-model.number="form.summary"
              id="summary"
              type="number"
              min="0"
              step="0.01"
              :error="$v.form.summary.$error"
            />
            <template #error>
              <div v-if="$v.form.summary.$dirty && !$v.form.summary.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Наименование поставщика (подрядчика, исполнителя)" required>
            <app-select
              @search="onSupplierSearch"
              v-model="form.supplier"
              :reduce="item => item.id"
              :options="supplier_options"
              :filterable="false"
              :clearable="true"
              label="name"
              placeholder="Введите название организации или ИНН"
              class="select"
              :class="{ 'select--error': $v.form.supplier.$error }"
            >
              <template #open-indicator>
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.inn }})</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }} ({{ option.inn }})</div>
              </template>
            </app-select>
            <template #error>
              <div v-if="$v.form.supplier.$dirty && !$v.form.supplier.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="date_provision_service" label="Срок оказания услуг (поставки товара, выполнения работ)">
            <app-input
              @paste.native.prevent
              v-model="form.date_provision_service"
              v-mask="'99.99.9999'"
              id="date_provision_service"
              autocomplete="off"
            />
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label-for="date_provision_service_number" label="Срок оказания услуг (число месяца)">
            <app-input
              v-model.number="form.date_provision_service_number"
              type="number"
              id="date_provision_service_number"
              min="1"
              max="31"
            />
          </app-form-group>
          <app-form-group label-for="date_sign_product" label="Дата выставление документов (товарная накладная, акт)">
            <app-input
              @paste.native.prevent
              v-model="form.date_sign_product"
              v-mask="'99.99.9999'"
              id="date_sign_product"
              autocomplete="off"
            />
          </app-form-group>
          <app-form-group label-for="date_sign_product_number" label="Дата выставление документов (число месяца)">
            <app-input
              v-model.number="form.date_sign_product_number"
              type="number"
              id="date_sign_product_number"
              min="1"
              max="31"
            />
          </app-form-group>
          <app-form-group label-for="enforcement_method" label="Способ обеспечения исполнения контракта (банковская гарантия, денежные средства)" required>
            <app-input
              v-model="form.enforcement_method"
              id="enforcement_method"
              :error="$v.form.enforcement_method.$error"
            />
            <template #error>
              <div v-if="$v.form.enforcement_method.$dirty && !$v.form.enforcement_method.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="enforcement_amount_contract" label="Размер обеспечения исполнения контракта">
            <app-input
              v-model.number="form.enforcement_amount_contract"
              id="enforcement_amount_contract"
              type="number"
              min="0"
              step="0.01"
            />
          </app-form-group>
          <app-form-group label-for="enforcement_date_return" label="Дата возврата обеспечения исполнения контракта">
            <app-input
              @paste.native.prevent
              v-model="form.enforcement_date_return"
              v-mask="'99.99.9999'"
              id="enforcement_date_return"
              autocomplete="off"
            />
          </app-form-group>
          <app-form-group label="Способ обеспечения исполнения гарантийных обязательств (банковская гарантия, денежные средства)" required>
            <app-select
              v-model="form.enforcement_way"
              :reduce="item => item.id"
              :options="enforcement_options"
              :filterable="true"
              :clearable="true"
              :searchable="true"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.enforcement_way.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </app-select>
            <template #error>
              <div v-if="$v.form.enforcement_way.$dirty && !$v.form.enforcement_way.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="enforcement_amount_security" label="Размер обеспечения исполнения гарантийных обязательств">
            <app-input
              v-model.number="form.enforcement_amount_security"
              id="enforcement_amount_security"
              type="number"
              min="0"
              step="0.01"
            />
          </app-form-group>
          <app-form-group label-for="enforcement_date_security" label="Срок предоставления гарантийных обязательств">
            <app-input
              @paste.native.prevent
              v-model="form.enforcement_date_security"
              v-mask="'99.99.9999'"
              id="enforcement_date_security"
              autocomplete="off"
            />
          </app-form-group>
          <app-form-group label-for="enforcement_date_return_security" label="Дата возврата обеспечения исполнения гарантийных обязательств">
            <app-input
              @paste.native.prevent
              v-model="form.enforcement_date_return_security"
              v-mask="'99.99.9999'"
              id="enforcement_date_return_security"
              autocomplete="off"
            />
          </app-form-group>
          <app-form-group label-for="due_date" label="Срок оплаты">
            <app-input
              v-model="form.due_date"
              id="due_date"
            />
          </app-form-group>
          <app-form-group label="Категория" required>
            <app-select
              v-model="form.category"
              :reduce="item => item.id"
              :options="category_options"
              :filterable="true"
              :clearable="true"
              :searchable="true"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.category.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </app-select>
            <template #error>
              <div v-if="$v.form.category.$dirty && !$v.form.category.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Подкатегория" required>
            <app-select
              v-model="form.sub_category"
              :reduce="item => item.id"
              :options="subcategory_options"
              :filterable="true"
              :clearable="true"
              :searchable="true"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.sub_category.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </app-select>
            <template #error>
              <div v-if="$v.form.sub_category.$dirty && !$v.form.sub_category.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Вложения">
            <app-multi-uploader
              v-model="form.docs"
              :limit="10"
            />
          </app-form-group>
          <app-form-group>
            <app-checkbox
              v-model="form.need_renewal"
              label="Требует продления"
            />
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button :disabled="$v.form.$error" ref="submit">Добавить контракт</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import {debounce} from 'lodash'
import { required } from 'vuelidate/lib/validators'
const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'ContractsCreate',
  data() {
    return {
      form: {},
      department_options: [],
      city_options: [],
      allocation_options: [],
      stage_options: [],
      enforcement_options: [],
      executor_options: [],
      supplier_options: [],
      category_options: [],
      subcategory_options: [],
    }
  },
  validations: {
    form: {
      kosgu: { required },
      name: { required },
      city: { required },
      executor: { required },
      main_price: { required },
      department: { required },
      type_allocation: { required },
      stage: { required },
      date_end: { required },
      date_documents_ur: { underscorePresent },
      date_on_buy: { underscorePresent },
      date_end_req: { underscorePresent },
      summary: { required },
      supplier: { required },
      enforcement_method: { required },
      enforcement_way: { required },
      category: { required },
      sub_category: { required },
    },
  },
  created() {
    this.$store.dispatch('contracts/GET_CITY')
      .then(response => {
        this.city_options = response.data
      })
    this.$store.dispatch('contracts/GET_DEPARTMENTS')
      .then(response => {
        this.department_options = response.data
      })
    this.$store.dispatch('contracts/GET_ALLOCATION')
      .then(response => {
        this.allocation_options = response.data
      })
    this.$store.dispatch('contracts/GET_STAGE')
      .then(response => {
        this.stage_options = response.data
      })
    this.$store.dispatch('contracts/GET_ENFORCEMENT')
      .then(response => {
        this.enforcement_options = response.data
      })
    this.$store.dispatch('contracts/GET_USERS')
      .then(response => {
        this.executor_options = response.data
      })
    this.$store.dispatch('contracts/GET_CATEGORY')
      .then(response => {
        this.category_options = response.data
      })
    this.$store.dispatch('contracts/GET_SUBCATEGORY')
      .then(response => {
        this.subcategory_options = response.data
      })
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('contracts/POST_DATA', this.normalizeForm())
        .then(() => {
          this.$refs.submit.preload = false
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Контракт добавлен'
          })
          this.$router.push({ name: 'contracts-list' })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    normalizeForm() {
      const { ...normalizedForm } = this.form
      normalizedForm.docs = normalizedForm.docs.map(item => item.id)
      return normalizedForm
    },
    onSupplierSearch(search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('supplier/GET_LIST', {page: 1, page_size: '', search}).then(response => {
        vm.supplier_options = response.data.results
        loading(false)
      })
    }, 350),
  }
}
</script>

